import { useCallback, useEffect } from 'react';

import { AIAnalysisProgressType } from 'generated-types';
import { UseQueryResult, useQuery } from 'react-query';
import { billingQueryKeys, getSignUpInitiativeStatus } from 'shared/api/subscription.service';

type TProps = {
	completedCallback?: VoidFunction | (() => Promise<void>);
	failedCallback?: VoidFunction;
	signUpInitiativeId: string | null;
};

export const useGetSignUpInitiativeStatusPolling = ({
	completedCallback,
	failedCallback,
	signUpInitiativeId,
}: TProps): UseQueryResult<AIAnalysisProgressType, unknown> => {
	const queryResult = useQuery({
		queryFn: () => getSignUpInitiativeStatus(signUpInitiativeId!),
		enabled: !!signUpInitiativeId,
		...billingQueryKeys.billing.initiativeStatus({ signUpInitiativeId }),
		refetchInterval: (data) => {
			if (data === AIAnalysisProgressType.WAITING_FOR_AI_RESPONSE) {
				return 3000;
			}

			return false;
		},
	});

	const memoizedCompletedCallback = useCallback(() => {
		completedCallback?.();
	}, [completedCallback]);

	const memoizedFailedCallback = useCallback(() => {
		failedCallback?.();
	}, [failedCallback]);

	useEffect(() => {
		if (queryResult.data === AIAnalysisProgressType.COMPLETED) {
			memoizedCompletedCallback();
		}

		if (queryResult.data === AIAnalysisProgressType.FAILED) {
			memoizedFailedCallback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryResult.data]);

	return queryResult;
};
