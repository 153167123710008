import { isNil, startCase, toLower } from 'lodash';

export const validatePhoneNumber = (_, value): Promise<void> => {
	const isValid = /^[0-9]{10}$/.test(value) || isNil(value);
	if (!isValid) {
		return Promise.reject('Please enter a 10-digit number.');
	}
	return Promise.resolve();
};

export const validateNumberOfLicenses =
	(customMaxLength?: number) =>
	(_, value: string | number): Promise<void> => {
		const maxLength = customMaxLength || 99;
		if (+value > maxLength) {
			return Promise.reject(`Maximum Number of Licenses is ${maxLength}`);
		}
		return Promise.resolve();
	};

export const transformString = (str: string): string => {
	const trimmedStr = str.endsWith('_ADDON') ? str.slice(0, -6) : str;
	return startCase(toLower(trimmedStr));
};
