import { FC, Suspense, lazy } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import RegistrationPlanFormPage from 'pages/RegistrationPlanFormPage';
import SignupPage from 'pages/SignupPage';
import SuccessPage from 'pages/SuccessPage';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WithLoader from 'shared/ui/WithLoader';

import styles from './App.module.scss';

const SignupLayoutLazy = lazy(() => import('Layout/ui/SignupLayout'));
const RegisterLayoutLazy = lazy(() => import('Layout'));

const App: FC = (): JSX.Element => {
	ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS!);

	return (
		<main className={styles.appContainer}>
			<BrowserRouter>
				<Suspense fallback={<WithLoader isLoading={true} />}>
					<Routes>
						<Route path="/" element={<RegisterLayoutLazy />}>
							<Route
								index
								element={
									<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY!}>
										<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
											<RegistrationPlanFormPage />
										</GoogleOAuthProvider>
									</GoogleReCaptchaProvider>
								}
							/>
							<Route path="success" element={<SuccessPage />} />
						</Route>

						<Route path="signup" element={<SignupLayoutLazy />}>
							<Route
								index
								element={
									<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY!}>
										<SignupPage />
									</GoogleReCaptchaProvider>
								}
							/>
							<Route path="success" element={<SuccessPage isFromSingUp />} />
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</main>
	);
};

export default App;
