import { Dispatch, FC, SetStateAction, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Input, message } from 'antd';
import { ReactComponent as CouponCheckIcon } from 'assets/svg/couponCheck.svg';
import classNames from 'classnames';
import { SubscriptionDto } from 'generated-types';
import { useMutation } from 'react-query';
import { applySubscriptionCoupon, deleteCoupon } from 'shared/api/subscription.service';

import styles from './ApplyCouponField.module.scss';

type TApplyCouponFieldProps = {
	subscriptionResponse: SubscriptionDto;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
	theme: 'light' | 'dark';
};

const ApplyCouponField: FC<TApplyCouponFieldProps> = ({
	subscriptionResponse,
	setSubscriptionResponse,
	theme,
}): JSX.Element => {
	const [applyCoupon, setApplyCoupon] = useState(false);
	const [couponValue, setCouponValue] = useState('');

	const { couponId } = subscriptionResponse;

	const { mutateAsync: applySubscriptionCouponAsync, isLoading: isApplyLoading } = useMutation({
		mutationFn: (couponId: string) => applySubscriptionCoupon(couponId),
	});

	const { mutateAsync: deleteCouponAsync, isLoading: isLoadingDeleteCoupon } = useMutation({
		mutationFn: () => deleteCoupon(),
	});

	const handleCouponApply = async (): Promise<void> => {
		if (!couponValue) return;
		try {
			const response = await applySubscriptionCouponAsync(couponValue);
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	const handleDeleteCoupon = async (): Promise<void> => {
		try {
			setApplyCoupon(false);
			setCouponValue('');

			const response = await deleteCouponAsync();
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};
	return (
		<>
			{!couponId && !applyCoupon && (
				<Button
					type="link"
					className={classNames(styles.addPromotionCode, {
						[styles.addPromotionCodeDark]: theme === 'dark',
					})}
					onClick={(): void => setApplyCoupon(true)}
				>
					Add promotion code
				</Button>
			)}
			{!couponId && applyCoupon && (
				<Input
					value={couponValue}
					onChange={(e): void => setCouponValue(e.target.value)}
					style={{ width: '50%' }}
					size="small"
					placeholder="Add promotion code"
					suffix={
						<Button
							loading={isApplyLoading}
							onClick={handleCouponApply}
							size="small"
							type="link"
							className={styles.applyButton}
						>
							Apply
						</Button>
					}
				/>
			)}
			{couponId && (
				<Flex className={styles.appliedCoupon} align="center" justify="space-between" gap={8}>
					<Flex className={styles.left} align="center">
						<CouponCheckIcon />
					</Flex>
					<Flex className={styles.center} align="center" justify="flex-start">
						<p className={styles.couponIdText}>
							{couponId} <span className={styles.text}>applied</span>
						</p>
					</Flex>
					<Button
						shape="circle"
						size="small"
						className={styles.right}
						type="text"
						loading={isLoadingDeleteCoupon}
						icon={<CloseOutlined className={styles.couponRemove} onClick={handleDeleteCoupon} />}
					/>
				</Flex>
			)}
		</>
	);
};

export default ApplyCouponField;
