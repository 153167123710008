import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button, Col, Flex, Form, Input, InputNumber, Row, message } from 'antd';
import classNames from 'classnames';
import { SubscriptionDto } from 'generated-types';
import { debounce } from 'lodash';
import { useMutation } from 'react-query';
import { changeNumberOfLicenses } from 'shared/api/subscription.service';
import { tiersTransform } from 'shared/constants/constants';
import { transformString, validateNumberOfLicenses } from 'shared/lib/lib';
import ApplyCouponField from 'shared/ui/ApplyCouponField';

import styles from './SignUpSummary.module.scss';

type TSubscriptionSummaryProps = {
	subscriptionResponse: SubscriptionDto;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
	goBacHandler: VoidFunction;
	isProcessing: boolean;
};

const SignUpSummary = ({
	subscriptionResponse,
	setSubscriptionResponse,
	goBacHandler,
	isProcessing,
}: TSubscriptionSummaryProps): ReactElement => {
	const {
		tierProductType,
		amount,
		tierProductPrice,
		reportsAddonPrices,
		amountDiscount,
		percentageDiscount,
	} = subscriptionResponse;

	const initiativesValues = Form.useWatch('applicationModuleTypes');
	const numberOfLicenses = Form.useWatch('numberOfLicenses');

	const { mutateAsync: changeNumberOfLicensesAsync } = useMutation({
		mutationFn: (value: number) => changeNumberOfLicenses(value),
	});

	const handleNumberOfLicensesChange = debounce(async (value: number): Promise<void> => {
		if (value > 50 || value < 1) return;

		try {
			const response = await changeNumberOfLicensesAsync(value || 1);
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	}, 1000);

	const goBack = async (): Promise<void> => {
		await handleNumberOfLicensesChange(1);
		goBacHandler();
	};

	return (
		<Flex vertical gap={16} justify="space-between" className={styles.container}>
			<Flex vertical gap={16}>
				<h2 className={styles.title}>Enjoy your 7-day free trial</h2>
				<p className={styles.blockTitle}>Subscription</p>
				<Flex vertical gap={16} className={styles.sectionContainer}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								label="First Name"
								name="firstName"
								rules={[{ required: true, message: 'Required field' }]}
								style={{ textAlign: 'left' }}
							>
								<Input placeholder="Enter First Name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Last Name"
								name="lastName"
								rules={[{ required: true, message: 'Required field' }]}
								style={{ textAlign: 'left' }}
							>
								<Input placeholder="Enter Last Name" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								label="Email"
								name="email"
								rules={[{ required: true, message: 'Required field', whitespace: true }]}
								style={{ textAlign: 'left' }}
							>
								<Input type="email" placeholder="Enter Email" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Password"
								name="password"
								rules={[{ required: true, message: 'Required field' }]}
								style={{ textAlign: 'left' }}
							>
								<Input.Password id="password" type="password" placeholder="Enter Password" />
							</Form.Item>
						</Col>
					</Row>
				</Flex>
				<Row align="middle" className={styles.sectionContainer}>
					<Col
						span={12}
						style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 16 }}
					>
						<p>Paid Licenses</p>
						<Form.Item
							noStyle
							name="numberOfLicenses"
							rules={[
								{ required: true, message: 'Required field' },
								{
									validator: validateNumberOfLicenses(50),
									message: 'Maximum Number of Licenses is 50',
								},
							]}
						>
							<InputNumber
								type="number"
								min={1}
								max={50}
								className={styles.inputNumber}
								onChange={handleNumberOfLicensesChange}
							/>
						</Form.Item>
					</Col>
					<Col span={12} style={{ textAlign: 'end' }}>
						Free Collaborators = {+numberOfLicenses * 5}
					</Col>
				</Row>
				<Flex vertical gap={12} className={styles.sectionContainer}>
					<Flex align="center" justify="space-between">
						<p className={styles.text}>{tiersTransform[tierProductType!]}</p>
						<p className={styles.textSmall}>
							per month / billed monthly{'   '}
							<span className={styles.text}>${tierProductPrice! / 100}</span>
						</p>
					</Flex>
					<Flex vertical gap={12}>
						{reportsAddonPrices &&
							Object.entries(reportsAddonPrices).map(([key, price]: [string, number]) => (
								<Flex key={key} justify="space-between" align="center">
									<p className={styles.text}>{transformString(key)}</p>
									<p className={styles.textSmall}>
										per month / billed monthly{'   '}
										<span className={styles.text}>${price / 100}</span>
									</p>
								</Flex>
							))}
					</Flex>
				</Flex>
				<Flex vertical align="flex-start" gap={12} className={styles.sectionContainer}>
					<Flex style={{ width: '100%' }} justify="space-between" align="center">
						<p className={styles.text}>Subtotal</p>
						<p className={styles.text}>${amount! / 100}</p>
					</Flex>
					<ApplyCouponField
						subscriptionResponse={subscriptionResponse}
						setSubscriptionResponse={setSubscriptionResponse}
						theme="dark"
					/>
					<Flex style={{ width: '100%' }} justify="space-between" align="center">
						<p className={styles.text}>Discount</p>
						<p className={styles.text}>
							{amountDiscount ? `-${amountDiscount / 100}$` : '0$'} ({percentageDiscount || 0}%)
						</p>
					</Flex>
				</Flex>
				<Flex vertical align="flex-start" gap={12}>
					<Flex style={{ width: '100%' }} justify="space-between" align="center">
						<p className={styles.text}>Total after trial</p>
						<p className={styles.text}>${amount! / 100}</p>
					</Flex>

					<Flex style={{ width: '100%' }} justify="space-between" align="center">
						<p className={classNames(styles.text, styles.textBold)}>Total due today</p>
						<p className={classNames(styles.text, styles.textBold)}>${0}</p>
					</Flex>
				</Flex>
			</Flex>
			<Flex gap={16}>
				<Button style={{ flex: 1 }} onClick={goBack} disabled={isProcessing}>
					Maybe Later
				</Button>
				<Button
					style={{ flex: 1 }}
					type="primary"
					form="sign-up-form"
					htmlType="submit"
					disabled={isProcessing || initiativesValues?.length === 0}
					loading={isProcessing}
				>
					Sign Me Up
				</Button>
			</Flex>
		</Flex>
	);
};

export default SignUpSummary;
