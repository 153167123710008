import { Dispatch, FC, SetStateAction } from 'react';

import { Flex } from 'antd';
import classNames from 'classnames';
import { SubscriptionDto } from 'generated-types';
import { tiersTransform } from 'shared/constants/constants';
import { transformString } from 'shared/lib/lib';
import ApplyCouponField from 'shared/ui/ApplyCouponField';

import styles from './Summary.module.scss';

type TSummaryProps = {
	subscriptionResponse: SubscriptionDto;
	seSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
};

const Summary: FC<TSummaryProps> = ({
	subscriptionResponse,
	seSubscriptionResponse,
}): JSX.Element => {
	const {
		tierProductType,
		amount,
		tierProductPrice,
		reportsAddonPrices,
		amountDiscount,
		percentageDiscount,
	} = subscriptionResponse;

	return (
		<Flex vertical className={styles.container} gap={16}>
			<Flex align="center" justify="space-between" className={styles.header}>
				<p className={styles.tier}>{tiersTransform[tierProductType!]}</p>
				<p className={styles.tierPrice}>
					${tierProductPrice! / 100}
					<span className={styles.tierPriceSub}>/month</span>
				</p>
			</Flex>
			{reportsAddonPrices && (
				<Flex vertical gap={12} className={styles.sectionContainer}>
					{Object.entries(reportsAddonPrices).map(([key, price]) => (
						<Flex key={key} justify="space-between" align="center">
							<p className={styles.text}>{transformString(key)}</p>
							<p className={styles.textSmall}>
								per month / billed monthly{'   '}
								<span className={styles.text}>${price / 100}</span>
							</p>
						</Flex>
					))}
				</Flex>
			)}
			<Flex vertical align="flex-start" gap={12} className={styles.sectionContainer}>
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Subtotal</p>
					<p className={styles.text}>${amount! / 100}</p>
				</Flex>
				<ApplyCouponField
					subscriptionResponse={subscriptionResponse}
					setSubscriptionResponse={seSubscriptionResponse}
					theme="light"
				/>
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Discount</p>
					<p className={styles.text}>
						{amountDiscount ? `-${amountDiscount / 100}$` : '0$'} ({percentageDiscount || 0}%)
					</p>
				</Flex>
			</Flex>
			<Flex vertical align="flex-start" gap={12}>
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Total after trial</p>
					<p className={styles.text}>${amount! / 100}</p>
				</Flex>

				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={classNames(styles.text, styles.textBold)}>Total due today</p>
					<p className={classNames(styles.text, styles.textBold)}>${amount! / 100}</p>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Summary;
