import { FC } from 'react';

import { Flex, Typography } from 'antd';
import classNames from 'classnames';

import styles from './Tier.module.scss';

const { Title, Text } = Typography;

type TTierProps = {
	price?: number;
	title: string;
};

const Tier: FC<TTierProps> = ({ price = 0, title }): JSX.Element => {
	return (
		<Flex className={classNames(styles.container)} justify="center" align="center" vertical>
			<Flex className={classNames(styles.titleContainer)} justify="space-between" align="center">
				<Title level={2} className={styles.title}>
					{title}
				</Title>
				<Flex align="flex-end">
					<Title level={2} className={styles.price}>
						{`$${price / 100}`}
					</Title>
					<Text className={styles.text}> {`/month`}</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Tier;
