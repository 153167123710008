import { FC } from 'react';

import { Flex, Form } from 'antd';
import { TSubscriptionPricesInner } from 'shared/api/subscription.service';

import Tier from '../Tier';

type TTiersContainer = {
	prices: TSubscriptionPricesInner | undefined;
};

const TiersContainer: FC<TTiersContainer> = ({ prices }): JSX.Element => {
	const subscriptionPlanTypesLength = Form.useWatch('subscriptionPlanTypes')?.length || 0;

	return (
		<Flex style={{ width: '100%' }} gap={10} justify="center">
			{subscriptionPlanTypesLength === 0 && <Tier price={0} title="Tier" />}
			{subscriptionPlanTypesLength === 1 && <Tier price={prices?.TIER_1} title="Tier 1" />}
			{subscriptionPlanTypesLength === 2 && <Tier price={prices?.TIER_2} title="Tier 2" />}
			{subscriptionPlanTypesLength === 3 && <Tier price={prices?.TIER_3} title="Tier 3" />}
		</Flex>
	);
};

export default TiersContainer;
