import { ReactComponent as AgileProductDevelopmentIcon } from 'assets/svg/agileProductDevelopment.svg';
import { ReactComponent as ImproveProcessesIcon } from 'assets/svg/improveProcesses.svg';
import { ReactComponent as ManageProjectsIcon } from 'assets/svg/manageProjects.svg';
import { ReactComponent as RunRetroOrAARIcon } from 'assets/svg/runRetroOrAAR.svg';
import { ReactComponent as StartBusinessIcon } from 'assets/svg/startBusiness.svg';
import { ReactComponent as StartBusinessLiteIcon } from 'assets/svg/startBusinessLite.svg';
import { SignUpInitiativeToolResponse } from 'generated-types';
import { SHORTCUT_BUTTONS } from 'widgets/SignupForm/SignupForm.types';

import { TPromptItemType } from './SignupFormStepOne.types';

export const buttonItems: TPromptItemType[] = [
	{
		title: 'Start a Business',
		icon: StartBusinessIcon,
		key: SHORTCUT_BUTTONS.startBusiness,
	},
	{
		title: 'Start a Business (Lite)',
		icon: StartBusinessLiteIcon,
		key: SHORTCUT_BUTTONS.startBusinessLite,
	},
	{
		title: 'Manage Projects',
		icon: ManageProjectsIcon,
		key: SHORTCUT_BUTTONS.manageProjects,
	},

	{
		title: 'Agile Product Development',
		icon: AgileProductDevelopmentIcon,
		key: SHORTCUT_BUTTONS.agileProductDevelopment,
	},
	{
		title: 'Run a Retro or AAR',
		icon: RunRetroOrAARIcon,
		key: SHORTCUT_BUTTONS.runRetroOrAAR,
	},
	{
		title: 'Improve Processes',
		icon: ImproveProcessesIcon,
		key: SHORTCUT_BUTTONS.improveProcesses,
	},
];

export const startBusinessFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'PLAN',
	'FORECAST',
	'ACTION_PLAN',
	'OPERATING_MODEL',
	'INDUSTRY_REPORTS',
	'PROCESS_REPORTS',
	'STORYBOARD',
];

export const startBusinessLightFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] =
	['PLAN', 'FORECAST', 'ACTION_PLAN', 'STORYBOARD', 'FILE_EXPLORER'];

export const manageProjectsFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'PROJECTS',
	'RETROSPECTIVES',
	'DIAGRAMS',
	'STORYBOARD',
];

export const agileProductDevelopmentFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] =
	['PROJECTS', 'RETROSPECTIVES', 'DIAGRAMS', 'STORYBOARD'];

export const runRetroOrAARFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'RETROSPECTIVES',
	'STORYBOARD',
	'ACTION_PLAN',
	'DIAGRAMS',
];

export const improveProcessesFormValues: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'OPTIMIZATION',
	'PROCESS_REPORTS',
	'ACTION_PLAN',
	'DIAGRAMS',
	'STORYBOARD',
	'RETROSPECTIVES',
];
