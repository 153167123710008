import { ReactElement, useState } from 'react';

import { Flex, Form, message } from 'antd';
import { SignupFormStepOne } from 'fetures/SignupFormSteps';
import SignupFormStepTwo from 'fetures/SignupFormSteps/ui/SignupFormStepTwo';
import {
	CustomerRequest,
	SignUpInitiativeRequest,
	SignUpInitiativeToolResponse,
	SubscriptionDto,
	SubscriptionRequest,
} from 'generated-types';
import { pick } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	billingQueryKeys,
	createSignUpInitiatives,
	createSubscription,
	finalizeSubscription,
	getSignUpInitiativeTools,
	validateEmail,
} from 'shared/api/subscription.service';
import { useGetSignUpInitiativeStatusPolling } from 'shared/hooks/useGetSignUpInitiativeStatusPolling';

import { getSubscriptionPayload } from './SignupForm.lib';
import styles from './SignupForm.module.scss';

const SignupForm = (): ReactElement => {
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [formStep, setFormStep] = useState<0 | 1>(0);
	const [signUpInitiativeId, setSignUpInitiativeId] = useState<string | null>(null);
	const [subscriptionResponse, setSubscriptionResponse] = useState<SubscriptionDto | null>(null);

	const [isProcessing, setIsProcessing] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { refetch } = useQuery({
		...billingQueryKeys.billing.initiativeTools({ signUpInitiativeId }),
		queryFn: () => getSignUpInitiativeTools(signUpInitiativeId!),
		enabled: false,
	});

	const { mutateAsync: createSignUpInitiativesAsync } = useMutation({
		mutationFn: (payload: SignUpInitiativeRequest) => createSignUpInitiatives(payload),
	});

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: SubscriptionRequest) => createSubscription(formValues),
	});

	const { mutateAsync: finalizeSubscriptionAsync } = useMutation({
		mutationFn: (payload: CustomerRequest) => finalizeSubscription(payload),
	});

	const { mutateAsync: validateEmailAsync } = useMutation({
		mutationFn: (params: { email: string }) => validateEmail(params),
	});

	const formSetter = (
		tools: SignUpInitiativeToolResponse[],
	): {
		checkedTools: SignUpInitiativeToolResponse[];
		toolsToSet: SignUpInitiativeToolResponse['applicationModuleType'][];
	} => {
		const checkedTools = tools.filter((tool) => tool.isChecked);
		const toolsToSet = checkedTools.map((tool) => tool.applicationModuleType);
		form.setFieldValue('applicationModuleTypes', toolsToSet);

		return { checkedTools, toolsToSet };
	};

	const completedCallback = async (): Promise<void> => {
		try {
			const { data: toolsResponse = [] } = await refetch();
			const { toolsToSet } = formSetter(toolsResponse);
			const payload = getSubscriptionPayload(toolsToSet);
			const response = await createSubscriptionAsync(payload);
			setSubscriptionResponse(response);
			setFormStep(1);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		} finally {
			setIsProcessing(false);
		}
	};

	const goBacHandler = (): void => {
		setFormStep(0);
		setSignUpInitiativeId(null);
		setSubscriptionResponse(null);
		form.resetFields();
	};

	useGetSignUpInitiativeStatusPolling({ signUpInitiativeId, completedCallback });

	const onFinish = async (formValues): Promise<void> => {
		if (!executeRecaptcha) return;

		try {
			if (formStep === 0) {
				setIsProcessing(true);
				const recaptchaToken = await executeRecaptcha('signup_form_step_one');
				const payload = {
					query: formValues.query,
					captcha: recaptchaToken,
				};
				const { id } = await createSignUpInitiativesAsync(payload);
				setSignUpInitiativeId(id!);
			}
			if (formStep === 1) {
				setIsProcessing(true);
				const finalizePayload = pick(formValues, [
					'firstName',
					'lastName',
					'email',
					'password',
					'applicationModuleTypes',
				]);
				const response = await validateEmailAsync({ email: formValues.email });

				if (response.isValid) {
					const recaptchaToken = await executeRecaptcha('signup_form_step_two');
					await finalizeSubscriptionAsync({ ...finalizePayload, captcha: recaptchaToken });

					navigate('success');
				} else {
					form.setFields([
						{
							name: 'email',
							errors: ['Email already exists'],
						},
					]);
				}
			}
		} catch (e) {
			setIsProcessing(false);
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<Flex justify="center" gap={48} className={styles.container} id="form-container">
			<Form
				requiredMark={false}
				id="sign-up-form"
				form={form}
				layout="vertical"
				onFinish={onFinish}
				validateTrigger="onChange"
				initialValues={{ numberOfLicenses: 1 }}
			>
				{formStep === 0 && (
					<SignupFormStepOne
						isProcessing={isProcessing}
						setIsProcessing={setIsProcessing}
						setFormStep={setFormStep}
						setSubscriptionResponse={setSubscriptionResponse}
					/>
				)}
				{formStep === 1 && (
					<SignupFormStepTwo
						signUpInitiativeId={signUpInitiativeId!}
						subscriptionResponse={subscriptionResponse}
						setSubscriptionResponse={setSubscriptionResponse}
						goBacHandler={goBacHandler}
						isProcessing={isProcessing}
					/>
				)}
			</Form>
		</Flex>
	);
};

export default SignupForm;
