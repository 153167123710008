import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import {
	AIAnalysisProgressType,
	CustomerRequest,
	IdResponse,
	SignUpInitiativeRequest,
	SignUpInitiativeToolResponse,
	SubscriptionDto,
	SubscriptionRequest,
} from 'generated-types';
import axios, { API_PREFIX } from 'shared/configs/axios/axios';
import { sessionIdLSKey } from 'shared/constants/constants';

const CONTROLLER_PREFIX = 'subscriptions';

export enum ADD_ONS_VALUES {
	INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
	PROCESS_REPORTS = 'PROCESS_REPORTS',
}

export enum SUBSCRIPTIONS_PLANS {
	PLAN = 'PLAN',
	BUILD = 'BUILD',
	GROW = 'GROW',
	ENTERPRISE = 'ENTERPRISE',
}

export type TSubscriptionsPlan = keyof typeof SUBSCRIPTIONS_PLANS;

export type TSubscriptionsPlans = TSubscriptionsPlan[];

export type TSubscriptionAddon = keyof typeof ADD_ONS_VALUES;

export type TSubscriptionAddons = TSubscriptionAddon[];

export type TCreateSubscriptionPayload = {
	subscriptionPlanTypes: TSubscriptionsPlans;
	subscriptionAddonTypes: TSubscriptionAddons;
};

export enum TEAR_PRODUCT_TYPE {
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_3',
}

export enum TEAR_TOKEN_PRODUCT_TYPE {
	TIER_1_TOKEN = 'TIER_1_TOKEN',
	TIER_2_TOKEN = 'TIER_2_TOKEN',
	TIER_3_TOKEN = 'TIER_3_TOKEN',
}

export type TContactSalesPayload = {
	firstName: string;
	lastName: string;
	email: string;
	contactPhone: string;
	companyName: string;
};

export type TAddSubscriptionDetailsResponse = {
	id: string;
	amount: number;
	paymentUrl: string;
};

export type TSubscriptionPricesInner = {
	INDUSTRY_REPORTS_ADDON: number;
	PROCESS_REPORTS_ADDON: number;
	TIER_1: number;
	TIER_1_TOKEN: number;
	TIER_2: number;
	TIER_2_TOKEN: number;
	TIER_3: number;
	TIER_3_TOKEN: number;
};

export type TSubscriptionPrices = {
	subscriptionPrices: TSubscriptionPricesInner;
};

export type TSubscriptionFormData = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	numberOfLicenses: number;
} & TCreateSubscriptionPayload;

export const billingQueryKeys = createQueryKeyStore({
	billing: {
		subscription: () => ({
			queryKey: [localStorage.getItem(sessionIdLSKey) ?? undefined],
			queryFn: (): Promise<TSubscriptionPrices> => getPrices(),
		}),
		prices: () => ['prices'],
		initiativeStatus: ({ signUpInitiativeId }) => [signUpInitiativeId],
		initiativeTools: ({ signUpInitiativeId }) => [signUpInitiativeId],
	},
});

export const createSubscription = async (
	payload: SubscriptionRequest,
): Promise<SubscriptionDto> => {
	const sessionId = localStorage.getItem(sessionIdLSKey) ?? crypto.randomUUID();

	if (!localStorage.getItem(sessionIdLSKey)) {
		localStorage.setItem(sessionIdLSKey, sessionId);
	}

	const { data } = await axios.post<SubscriptionDto>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}`,
		payload,
	);
	return data;
};

export const changeNumberOfLicenses = async (
	numberOfLicenses: number,
): Promise<SubscriptionDto> => {
	const sessionId = getSessionId();

	const { data } = await axios.patch<SubscriptionDto>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/number-of-licenses`,
		{ numberOfLicenses },
	);
	return data;
};

export const contactSales = async (payload: TContactSalesPayload): Promise<void> => {
	await axios.post<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/enterprise`, payload);
};

export const getSubscription = async (): Promise<TSubscriptionPrices> => {
	const sessionId = getSessionId();

	const { data } = await axios.get(`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}`);

	return data;
};

export const getPrices = async (): Promise<TSubscriptionPrices> => {
	const { data } = await axios.get<TSubscriptionPrices>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/prices`,
	);

	return data;
};

export const validateDomainString = async (params = {}): Promise<void> => {
	await axios.get<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/validate-domain`, { params });
};

export const deleteCoupon = async (): Promise<SubscriptionDto> => {
	const sessionId = getSessionId();

	const { data } = await axios.delete<SubscriptionDto>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/coupons`,
	);

	return data;
};

export const applySubscriptionCoupon = async (couponId: string): Promise<SubscriptionDto> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	const { data } = await axios.post<SubscriptionDto>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/coupons/${couponId}/apply`,
	);
	return data;
};

export const finalizeSubscription = async (payload: CustomerRequest): Promise<void> => {
	const sessionId = getSessionId();

	await axios.post<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/finalize`, payload);
};

export const validateEmail = async (params: { email: string }): Promise<{ isValid: boolean }> => {
	const { data } = await axios.get<{ isValid: boolean }>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/validate-email`,
		{ params, timeout: 300000 },
	);

	return data;
};
export const getSignUpInitiativeStatus = async (
	signUpInitiativeId: string,
): Promise<AIAnalysisProgressType> => {
	const { data } = await axios.get<AIAnalysisProgressType>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/sign-up-initiatives/${signUpInitiativeId}/status`,
	);

	return data;
};
export const getSignUpInitiativeTools = async (
	signUpInitiativeId: string,
): Promise<SignUpInitiativeToolResponse[]> => {
	const { data } = await axios.get<SignUpInitiativeToolResponse[]>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/sign-up-initiatives/${signUpInitiativeId}/tools`,
	);

	return data;
};

export const createSignUpInitiatives = async (
	payload: SignUpInitiativeRequest,
): Promise<IdResponse> => {
	const { data } = await axios.post<IdResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/sign-up-initiatives`,
		payload,
	);

	return data;
};

const getSessionId = (): string => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	return sessionId;
};
