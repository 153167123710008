import { Dispatch, FC, SetStateAction } from 'react';

import { Checkbox, Flex, Form, message } from 'antd';
import {
	SignUpInitiativeToolResponse,
	SubscriptionDto,
	SubscriptionRequest,
} from 'generated-types';
import { startCase, toLower } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { billingQueryKeys, createSubscription } from 'shared/api/subscription.service';
import { getSubscriptionPayload } from 'widgets/SignupForm/SignupForm.lib';

import { initiativesOptions } from './SignUpInitiatives.constants';
import styles from './SignUpInitiatives.module.scss';

type TSignUpInitiativesProps = {
	signUpInitiativeId: string;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto | null>>;
};

const SignUpInitiatives: FC<TSignUpInitiativesProps> = ({
	signUpInitiativeId,
	setSubscriptionResponse,
}) => {
	const queryClient = useQueryClient();

	const toolsResponse = queryClient.getQueryData<SignUpInitiativeToolResponse[]>(
		billingQueryKeys.billing.initiativeTools({ signUpInitiativeId }).queryKey,
	);

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: SubscriptionRequest) => createSubscription(formValues),
	});

	const handleToolsChange = async (
		values: SignUpInitiativeToolResponse['applicationModuleType'][],
	): Promise<void> => {
		try {
			const payload = getSubscriptionPayload(values);
			const response = await createSubscriptionAsync(payload);
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	const mappedOptions = toolsResponse?.map(
		({ applicationModuleType, recommendation, example }) => ({
			label: startCase(toLower(applicationModuleType)),
			value: applicationModuleType,
			recommendation,
			example,
		}),
	);

	const options = toolsResponse ? mappedOptions : initiativesOptions;

	return (
		<Flex vertical gap={16} className={styles.container}>
			<h2 className={styles.title}>Try these Corvus Link tools</h2>
			<Form.Item name="applicationModuleTypes">
				<Checkbox.Group style={{ width: '100%' }} onChange={handleToolsChange}>
					<Flex vertical gap={8}>
						{options?.map(({ value, label, recommendation, example }) => (
							<Flex key={value} align="baseline" gap={12} className={styles.card}>
								<Checkbox value={value} />
								<Flex vertical align="flex-start">
									<p className={styles.label}>{label}</p>
									<p className={styles.recommendation}>{recommendation}</p>
									<p className={styles.example}>
										<span className={styles.exampleInner}>Example: </span>
										{example}
									</p>
								</Flex>
							</Flex>
						))}
					</Flex>
				</Checkbox.Group>
			</Form.Item>
		</Flex>
	);
};
export default SignUpInitiatives;
