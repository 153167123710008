import { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import { Divider, Flex } from 'antd';
import { SubscriptionDto } from 'generated-types';

import styles from './SignupFormStepTwo.module.scss';
import SignUpInitiatives from './ui/SignUpInitiatives';
import SignUpSummary from './ui/SignUpSummary';

type TSignupFormStepTwoProps = {
	signUpInitiativeId: string;
	subscriptionResponse: SubscriptionDto | null;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto | null>>;
	goBacHandler: VoidFunction;
	isProcessing: boolean;
};

const SignupFormStepTwo: FC<TSignupFormStepTwoProps> = ({
	signUpInitiativeId,
	subscriptionResponse,
	setSubscriptionResponse,
	goBacHandler,
	isProcessing,
}): ReactElement => {
	return (
		<Flex justify="space-between" className={styles.container}>
			<div className={styles.left}>
				<SignUpInitiatives
					signUpInitiativeId={signUpInitiativeId}
					setSubscriptionResponse={setSubscriptionResponse}
				/>
			</div>
			<Divider type="vertical" className={styles.divider} />
			<div className={styles.right}>
				<SignUpSummary
					subscriptionResponse={subscriptionResponse!}
					setSubscriptionResponse={setSubscriptionResponse}
					goBacHandler={goBacHandler}
					isProcessing={isProcessing}
				/>
			</div>
		</Flex>
	);
};

export default SignupFormStepTwo;
