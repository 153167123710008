import { FC } from 'react';

import { Button, Flex, Typography } from 'antd';
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg';
import { cloudCorvusRedirectLink } from 'shared/constants/constants';

import styles from './Success.module.scss';

const { Title, Text } = Typography;

type TSuccessProps = {
	isFromSingUp?: boolean;
};

const Success: FC<TSuccessProps> = ({ isFromSingUp }): JSX.Element => {
	return (
		<section className={styles.container} style={{ width: isFromSingUp ? '100%' : '100vw' }}>
			<Flex vertical gap={24} align="center" justify="center" className={styles.card}>
				<Title className={styles.title}>Thank you for choosing Corvus Link!</Title>
				<Text className={styles.text}>Your trial has been successfully activated.</Text>
				<MailIcon />
				<a
					style={{ width: '100%' }}
					href={cloudCorvusRedirectLink}
					target={isFromSingUp ? '_blank' : '_self'}
					rel="noreferrer"
				>
					<Button type="primary" style={{ width: '100%' }}>
						Go to Sign In
					</Button>
				</a>
			</Flex>
		</section>
	);
};

export default Success;
