import { SignUpInitiativeToolResponse, SubscriptionRequest } from 'generated-types';

const PLAN_TOOLS: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'PLAN',
	'FORECAST',
	'ACTION_PLAN',
	'STORYBOARD',
	'FILE_EXPLORER',
];
const BUILD_TOOLS: SignUpInitiativeToolResponse['applicationModuleType'][] = [
	'OPERATING_MODEL',
	'PROJECTS',
	'RETROSPECTIVES',
];
const GROW_TOOLS: SignUpInitiativeToolResponse['applicationModuleType'][] = ['OPTIMIZATION'];

export const getSubscriptionPayload = (
	tools: SignUpInitiativeToolResponse['applicationModuleType'][],
): SubscriptionRequest => {
	const result: SubscriptionRequest = {
		subscriptionPlanTypes: [],
		subscriptionAddonTypes: [],
	};

	if (tools.some((tool) => PLAN_TOOLS.includes(tool))) {
		result.subscriptionPlanTypes?.push('PLAN');
	}

	if (tools.some((tool) => BUILD_TOOLS.includes(tool))) {
		result.subscriptionPlanTypes?.push('BUILD');
	}

	if (tools.some((tool) => GROW_TOOLS.includes(tool))) {
		result.subscriptionPlanTypes?.push('GROW');
	}

	if (tools.includes('INDUSTRY_REPORTS')) {
		result.subscriptionAddonTypes?.push('INDUSTRY_REPORTS');
	}
	if (tools.includes('PROCESS_REPORTS')) {
		result.subscriptionAddonTypes?.push('PROCESS_REPORTS');
	}

	return result;
};
